import React from "react"
import { useStaticQuery, Link } from "gatsby"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

import Logo from "./logo"

const currentYear = new Date().getFullYear();

const Navv = () => {
  const {
    site: {
      siteMetadata: { social },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              linkedin
              mail
              youtube
            }
          }
        }
      }
    `
  )

  return (
    <Nav style={{ display: "flex" }}>
      <Div>
        <Link to="/missao">missão</Link>
        <Link to="/subscrever">newsletter</Link>
        <a target="_blank" href="/rss.xml" rel="noopener noreferrer">
          rss
        </a>
      </Div>
      <Div>
        <a
          target="_blank"
          href={`https://twitter.com/${social.twitter}`}
          rel="noopener noreferrer"
        >
          twitter
        </a>
        <a
          href={`https://www.youtube.com/channel/${social.youtube}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          youtube
        </a>
        <a
          href="https://vascosamouco.com/efeito-borboletra"
          target="_blank"
          rel="noopener noreferrer"
        >
          podcast
        </a>
        <a
          target="_blank"
          href={`https://www.linkedin.com/in/${social.linkedin}`}
          rel="noopener noreferrer"
        >
          linkedIn
        </a>
        <a href={`mailto:${social.mail}`} target="_top">
          e-mail
        </a>
      </Div>
      <Div>
        <Link className="no-link" to="/">
          <Logo />
        </Link>
        <div style={{ float: "right" }}>
          <small>© 2020 - {currentYear} Vasco Samouco</small>
        </div>
      </Div>
    </Nav>
  )
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 40px;

  > * {
    margin: 4px 0;
  }

  &:last-child {
    align-items: flex-end;
    flex: 1;
    margin-right: 0;
  }

  ${MOBILE_MEDIA_QUERY} {
    align-items: center;
    margin: 0 0 20px 0;
    width: 50%;

    &:last-child {
      width: 100%;
      align-items: center;
      margin-top: 30px;
    }
  }
`

const Nav = styled.nav`
  ${MOBILE_MEDIA_QUERY} {
    align-items: center;
    flex-wrap: wrap;
  }
`

export default Navv
