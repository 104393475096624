import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

import { rhythm } from "../utils/typography"
import Logo from "./logo"
import Nav from "./nav"
import Line from "./line"
import Burger from "./burger"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

function Layout(props) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `
  // )

  const { children } = props

  return (
    <Wrapper>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: "680px",
          padding: `${rhythm(1.1)} ${rhythm(3 / 4)}`,
          paddingTop: 0,
        }}
      >
        <Burger />
        <hr />
        <header
          style={{
            position: "relative",
            marginBottom: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link
              className="no-link"
              style={{
                color: `inherit`,
                display: "flex",
                alignItems: "center",
              }}
              to="/"
            >
              <Logo width={90} />
              <Title>
                VASCO<strong style={{ fontWeight: 800 }}>SAMOUCO</strong>
              </Title>
            </Link>
          </div>
          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <Toggle
                onClick={e => toggleTheme(theme === "light" ? "dark" : "light")}
              >
                {theme === "light" ? Moon : Sun}
              </Toggle>
            )}
          </ThemeToggler>
        </header>
        <main>{children}</main>
        <Line />
        <footer style={{ marginTop: rhythm(2) }}>
          <Nav />
        </footer>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
`

const Sun = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="Icon_metro-sun"
      data-name="Icon metro-sun"
      d="M15.463,9.435a5.385,5.385,0,1,0,5.385,5.385,5.391,5.391,0,0,0-5.385-5.385Zm0,9.231a3.846,3.846,0,1,1,3.846-3.846A3.846,3.846,0,0,1,15.463,18.666Zm0-10.769a.769.769,0,0,0,.769-.769V5.589a.769.769,0,1,0-1.538,0V7.128A.769.769,0,0,0,15.463,7.9Zm0,13.846a.769.769,0,0,0-.769.769v1.538a.769.769,0,0,0,1.538,0V22.512A.769.769,0,0,0,15.463,21.743ZM21.445,9.924l1.088-1.088a.769.769,0,0,0-1.088-1.088L20.358,8.837a.769.769,0,1,0,1.088,1.088ZM9.48,19.716,8.392,20.8A.769.769,0,0,0,9.48,21.891L10.568,20.8A.769.769,0,1,0,9.48,19.716Zm-.94-4.9a.769.769,0,0,0-.769-.769H6.232a.769.769,0,1,0,0,1.538H7.77A.769.769,0,0,0,8.54,14.82Zm16.154-.769H23.155a.769.769,0,1,0,0,1.538h1.538a.769.769,0,0,0,0-1.538ZM9.479,9.924a.769.769,0,0,0,1.088-1.088L9.479,7.749A.769.769,0,0,0,8.391,8.837Zm11.967,9.791A.769.769,0,0,0,20.359,20.8l1.088,1.088A.769.769,0,0,0,22.534,20.8Z"
      transform="translate(-5.463 -4.82)"
      fill="var(--primary)"
    />
  </svg>
)

const Toggle = styled.button`
  cursor: pointer;
  border: none;
  color: var(--primary);
  padding: 0;
  background: none;
  line-height: 0;
  outline: 0;
`

const Moon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.001"
    height="22.001"
    viewBox="0 0 22.001 22.001"
  >
    <path
      id="Icon_feather-moon"
      data-name="Icon feather-moon"
      d="M24.557,15.4a10.021,10.021,0,1,1-10.9-10.9,7.794,7.794,0,0,0,10.9,10.9Z"
      transform="translate(-3.557 -3.5)"
      fill="none"
      stroke="var(--primary)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

const Title = styled.h1`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  margin-left: 20px;
  color: var(--primary);
  text-transform: uppercase;

  ${MOBILE_MEDIA_QUERY} {
    margin-left: 10px;
  }

  @media only screen and (max-width: 350px) {
    strong {
      display: block;
    }
  }
`

export default Layout
