import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, Link, graphql } from "gatsby"

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index: 1;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  flex: 1;
  font-size: 14px;

  li {
    padding: 18px 10px;
  }

  a {
    box-shadow: none;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: var(--white);
    position: absolute;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(110%)")};
    top: 50px;
    width: 100%;
    transition: transform 300ms ease-in-out;
    border-bottom: ${({ open }) => (open ? "4px solid var(--primary)" : "0px")};

    a {
      font-size: 20px;
      margin: 20px auto;
    }
  }
`

const RightNav = ({ open }) => {
  const {
    site: {
      siteMetadata: { social },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              linkedin
              mail
              youtube
            }
          }
        }
      }
    `
  )

  return (
    <Ul open={open}>
      <Link to="/missao">missão</Link>
      <Link to="/subscrever">newsletter</Link>
      <a
        target="_blank"
        href={`https://twitter.com/${social.twitter}`}
        rel="noopener noreferrer"
      >
        twitter
      </a>
      <a
        href={`https://www.youtube.com/channel/${social.youtube}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        youtube
      </a>
      <a
        href="https://vascosamouco.com/efeito-borboletra"
        target="_blank"
        rel="noopener noreferrer"
      >
        podcast
      </a>
    </Ul>
  )
}

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  z-index: 20;
  display: none;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 30px;
    height: 3px;
    background-color: ${({ open }) => (open ? "#ccc" : "var(--primary)")};
    border-radius: 10px;
    transform-origin: 0px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Wrapper = styled.div`
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    display: block;
  }
`

const Burger = () => {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} />
    </Wrapper>
  )
}
export default Burger
