import React from "react"

const Logo = ({ width = 35 }) => (
  <span style={{ width: `${width}px`, display: "inline-block", lineHeight: 0 }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 54">
      <path
        id="logo"
        d="M240.692,249.5H280.7l-25.355-54h-16.88l-15.012,28.754L209.016,195.5H190.7l25.858,54h13.611l16.413-33.913,8.224,17.2H240.692Z"
        transform="translate(-190.7 -195.5)"
        fill="var(--primary)"
      />
    </svg>
  </span>
)

export default Logo
