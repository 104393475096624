import styled from "styled-components"

const Line = styled.div`
  width: 50px;
  height: 4px;
  background-color: var(--primary);
  margin-top: 1.5rem;
  display: block;
`

export default Line
