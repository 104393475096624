import Typography from "typography"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

import "./global.css"

const theme = {
  title: "Based on Wordpress Theme 2016",
  baseFontSize: "16px",
  baseLineHeight: 1.5,
  scaleRatio: 2,
  googleFonts: [],
  headerFontFamily: ["Merriweather", "Georgia", "serif"],
  bodyFontFamily: ["Merriweather", "Georgia", "serif"],
  bodyColor: "var(--text)",
  headerWeight: 700,
  bodyWeight: 300,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    "h1, h2": {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    h1: {
      fontSize: "2.441em",
      lineHeight: 1.1,
    },
    h2: {
      fontSize: "1.953em",
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.563em",
      lineHeight: 1.3,
    },
    h4: {
      fontSize: "1.25em",
      lineHeight: 1.4,
    },
    h5: {
      fontSize: options.baseFontSize,
      textTransform: "uppercase",
    },
    small: {
      fontSize: ".8em",
    },
    article: {
      lineHeight: "1.7",
      fontSize: "1.1em",
    },
    blockquote: {
      fontSize: "18px",
      fontStyle: "italic",
      paddingLeft: rhythm(13 / 16),
      marginLeft: rhythm(-1),
      borderLeft: `${rhythm(3 / 16)} solid var(--primary)`,
      marginRight: 0,
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo("15px"),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
    hr: {
      background: "var(--hr)",
    },
    ul: {
      listStyle: "disc",
    },
    "ul,ol": {
      marginLeft: 0,
    },
    [MOBILE_MEDIA_QUERY]: {
      "ul,ol": {
        marginLeft: rhythm(1),
      },
      blockquote: {
        fontSize: "16px",
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: "40px",
      marginBottom: "20px",
    },
    h6: {
      fontStyle: "italic",
    },
    a: {
      boxShadow: "0 1px 0 0 currentColor",
      color: "var(--primary)",
      textDecoration: "none",
      transition: "all 100ms linear",
    },
    "a:hover,a:active": {
      boxShadow: "none",
      background: "var(--primary)",
      color: "var(--white)",
    },
    "mark,ins": {
      background: "var(--primary)",
      color: "white",
      padding: `${rhythm(1 / 16)} ${rhythm(1 / 8)}`,
      textDecoration: "none",
    },
  }),
}

theme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
